footer {
  background-color: #6F2EB9;
  display: flex;
  justify-content: center;
  a{
    color: #fff;
  }
  a:hover{
    color: #000;
  }

  .footer-container{
    font-size: 20px;
    line-height: 24px;
    padding-left:200px;
  max-width:1400px;
    padding-left:200px;
  }
  .about-site,
  .about-site * {
    color: #fff;
  }
  .catalog-website-line {
    flex-wrap: wrap;
    > div{
      flex-basis: 100%;
    }
    .footer-columns {
      font-size: 19px;
  letter-spacing: 0;
  line-height: 24px;
      tr {
        td {
          p {
            margin:0;
          }
        }
      }
    }
    .ska-year-madeby{
      margin-top: 57px;
      font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
    }
      table {
        color: #fff;
        width:100%;
        tr{
          text-align: left;
        display: flex;
        justify-content: flex-start;
        td {
          flex-basis:50%;
        }
        }
    }
    justify-content: space-between;
    @media (max-width: 575px) {
      // mobile
      margin-bottom: 24px;
      flex-direction: column;
      > *:not(:last-child) {
        margin-bottom: 24px;
      }
      .link-with-icon {
        .icon-at-end {
          display: none;
        }
        .icon-at-start {
          display: block !important;
        }
      }
    }
    .link-with-icon {
      span {
        font-size: 14px;
        letter-spacing: 0.29px;
        white-space: nowrap;
      }
      .icon-at-start {
        display: none;
      }
    }
    .logo-text {
      @media (max-width: 575px) {
        // mobile
        font-size: 16px;
      }
    }
  }
}
.menu-block {
  display: flex;
}

@media (max-width: 575px) {
  .footer-container{
    padding-left:0 !important;
  }
}

