footer {
  #mc_embed_signup {
    form {
      padding: 0;
      margin-top: -6px
    }
    background-color: transparent;
    .mc-field-group {
      position: relative;
    }
    input[type="email"] {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid #fff;
      border-radius: 0;
      outline: none;
      color: white;
      padding-left: 0;
      padding-right: 20px;
      padding-bottom: 8px;
      font-size: 19px;
      text-indent: 0;
      // &:focus {
      // }
    }
    .input-submit-btn-icon {
      position: absolute;
      right: 0;
      bottom: 18px;
      background-color: transparent;
      border: none;
      padding: 0;
      cursor: pointer;
      img, svg {
        width: 16px;
        height: 16px;
        fill: none;
        stroke-width: 2;
        stroke: #ffffff;
      }
    }
    #mc-embedded-subscribe {
      display: none;
    }
  }
}