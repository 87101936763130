.App .main {

  .infos-content {
    & > .row:first-child {
      .info-title {
        padding-top: 0 !important;
      }
    }
    // .InfoItem {
    //   padding: 10px 0 10px 0;
    // }
    .info-title {
      font-size: 30px !important;
      letter-spacing: 0.58px !important;
      line-height: 40px !important;
      margin: 0 0 12px !important;
      padding: 80px 0 10px 0;
    }
  
    .info-content {
      h2 {
        font-size: 30px;
        letter-spacing: 0.58px;
        line-height: 40px !important;
        margin: 0 0 12px !important;
      }
    }
  }
}
