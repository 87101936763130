.Header {
  width: 200px;
  height: 100vh;
  text-align: center;
  $header-height: 96px;
  $mobile-header-height: 100px;
  .headroom-wrapper {
    width: 100%;
    max-width: $max-content-width;
    margin: 0px auto;
    background-color: $white;

    position: relative;
    z-index: 50;
  }
  .headroom {
    width: 100%;
    max-width: $max-content-width;
    margin: 0px auto;
    will-change: transform;

    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    &--unfixed {
      position: relative;
      transform: translateY(0);
    }
    &--scrolled {
      transition: transform 200ms ease-in-out;
    }
    &--unpinned {
      position: fixed;
      transform: translateY(-100%);
    }
    &--pinned {
      position: fixed;
      transform: translateY(0%);
    }
  }
  &.mobileMenuIsOpen,
  &.searchIsOpen {
    .headroom {
      position: fixed;
      transform: translateY(0%);
    }
  }
  .header-container {
    max-width: $max-content-width;
    height: 100vh;
    margin: 0 auto;
    background-color: #fff;
    align-items: center;
    a:hover {
      h1 {
        color: #000 !important;
      }
    }
  }
  .logo-container {
    padding-top: 10vh;
    width: 100%;
    
    .logo-link {
      z-index: 6;
      position: relative;
      // @media (max-width: 767px) {
      //   position: relative;
      //   z-index: 5;
      // }
      @include outline(0);
      .logo-text {
        @media (max-width: 767px) {
          font-size: 16px;
        }
      }
    }
  }
  .search-icon-div {
    position: absolute;
    width: 200px;
    bottom: 10vh;
    display: flex;
    justify-content: center;

    .search-icon {
      width: 60px;
      height: 60px;
      padding: 0;
      border: none;
      cursor: pointer;
      svg:hover {
        // rect {
        //   fill: #fff;
        // }
        path#search-icon {
          fill: #6f2eb9;
        }
      }
    }
  }
  .header-right {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .nav-container {
    display: none;
    @include breakpoint(xl) {
      display: block;
    }
  }
  .search-container {
    @media (max-width: 767px) {
      //mobile
      flex-direction: row-reverse;
      .nav-list {
        padding: 0 10px;
      }
    }
  }
  .cart-container {
    display: flex;
    align-items: center;
    margin-right: 25px;
    @include breakpoint(sm-down) {
      margin-right: 14px;
    }
  }
  .cart-icon {
    display: block;
    margin-right: 10px;
  }
  .cart-number {
    font-family: $global-font-family;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.23px;
    color: #131413;
  }
  .user-container {
    @include breakpoint(lg-down) {
      display: none;
    }
  }
  .login-link {
    @include outline(0);
    // cursor: pointer;
    // border: none;
    // background: none;
    // padding: 0;
    font-size: 24px;
    font-weight: lighter;
    color: #131413;
    text-decoration: none;
    &:not(:last-child) {
      margin-right: 25px;
    }
  }

  .hamburger-container {
    width: 48px;
    height: 48px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include breakpoint(xl) {
      display: none;
    }
  }
  .mobile-nav-container {
    display: none;
    position: fixed;
    top: $mobile-header-height;
    max-height: calc(100vh - #{$mobile-header-height});
    @include breakpoint(md) {
      top: $header-height;
      max-height: calc(100vh - #{$header-height});
    }
    left: 0;
    width: 100%;
    height: 100%;
    // top: 0;
    // padding: calc( #{$header-height} + 20px ) var(--horizontal-padding) 20px;
    // max-height: 100vh;
    padding: 20px var(--horizontal-padding) 20px;
    z-index: 40;

    overflow-y: auto;

    //padding: 20px var(--horizontal-padding) 20px;
    background-color: $white;
  }
  &.mobileMenuIsOpen {
    .mobile-nav-container {
      @include breakpoint(lg-down) {
        display: block;
      }
    }
  }
  .mobile-nav {
    // width: 100%;
    // height: 100%;
    //display: flex;
    // justify-content: center;
    // align-items: center;
  }
  .mobile-nav-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
  }
  .mobile-nav-item {
    &:not(:last-child) {
      margin-bottom: 28px;
    }
  }
  .mobile-nav-link {
    @include outline(0);
    font-size: 26px;
    line-height: 28px;
    //letter-spacing: 0.53px;
    &.active {
      text-decoration: underline;
    }
  }
  &.alwaysShow {
    .headroom {
      position: fixed;
    }
  }
}
