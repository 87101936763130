.Item {
  .item-link {
    display: block;
    transition: transform 0.2s ease-in-out;
    &:hover {
      .item-img {
        transform: translateY(-10px);
      }
      text-decoration: none;
      .item-artists,
      .item-title {
        text-decoration: underline;
        opacity: 0.8;
        color: #6F2EB9;
      }
    }
  }
  .item-img-container {
    position: relative;
    padding-bottom: 138%;
    margin-bottom: 6px;
  }
  .item-img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: left bottom;
    transition: transform 0.2s ease-in-out;
    z-index:0;
  }
  .item-text {
    text-align: left;
    // @media (max-width: 575px) {
    //   width: 120%;
    //   margin-left: -10%;
    // }
    // @include breakpoint(xl) {
    //   width: 150%;
    //   margin-left: -25%;
    // }
  }
  .item-artists,
  .item-title {
    display: block;
    word-break: break-word;
    font-size: 14.5px;
    // margin-top: 11px;
    line-height: 22px;
    letter-spacing: 0.01px;
    color: #282828;
    // text-align: center;
  }
  .item-title {
    font-weight: 500;
    // margin-bottom: 6px;
  }
  .item-artists {
    // margin-bottom: 6px;
    margin-top: 16px;
  }
  .price-container {
    padding-top: 6px;
  }
  .item-price {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 1px 10px;
    min-height: 20px;
    //min-width: 59px;
    border-radius: 10px;
    background-color: #f6f7f9;

    color: #96989e;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.22px;
    line-height: 1.4;
    text-decoration: none;
  }
  &.catalog-321-item {
    width: 264px;
    .item-text {
      text-align: center;
    }
  }
}
