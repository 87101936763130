.search-form{
  position: absolute;
  left:0;
  top:0;
  background-color: #6F2EB9;
}

.modal{
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 10;
  left: 0;
  background-color: #6F2EB9;
  padding-top: 10vh;
  .modal-container{
    max-width: 1400px;
    margin: 0 auto;
    display:flex;
    text-align: center;
    .buttons{
      width: 200px;
      flex-wrap: wrap;
      .nav-list{
        .nav-item:last-child{
          svg:hover {
            g {
              fill: #6F2EB9;
            }
            rect {
              fill: #fff;
            }
          }
        }
      
    }
      .modal-search{
        margin-top: 60px;
      }
    }
    .content{
      max-width: 1200px;
      width: 100%;
      text-align: left;
    }
  }
    }