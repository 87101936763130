.current-letter {
  width: 80px;
}

.artists-container{
  margin-top:120px;
  .name-block{
    display: flex;
    font-size: 30px;
    line-height: 64px;
    letter-spacing: 0.58px;
  }
}

.related-items{
  padding-top:60px;
}

.website-work{
  padding-top:30px;
}