html {
  scroll-behavior: smooth;
}
.content-container {
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: 575px) {
    padding: 0;
  }
}
@media (max-width: 575px) {
  [data-locale="tr"] {
    .i18n-tr {
      display: block;
    }
    .i18n-en {
      display: none;
    }
  }

  [data-locale="en"] {
    .i18n-en {
      display: block;
    }
    .i18n-tr {
      display: none;
    }
  }
}

.locale-selector {
  button {
    color: #9a9a9a;
    border: none;
    text-transform: none;
    width: auto;
    font-size: 26px;
    letter-spacing: 0.5px;
    line-height: 34px;
    padding: 0;
    margin-right: 10px;
  }
  .active {
    color: #000 !important;
    background: #fff !important;
  }
}
.App {
  button:hover {
    background-color: #6f2eb9;
  }
  margin: 0 auto;
  max-width: $max-content-width;
  min-height: 100vh;
  .header-fixed-container > div {
    position: absolute;
    top: 0;
    z-index: 2;
  }
  .menu-container {
    position: fixed;
    width: 100vw;
    max-width: 1400px;
    top: 0;
    left: 0;
    z-index: 5;

    .menu-block {
      padding-left: 15px;
      padding-right: 15px;
      margin-left: 200px;
      height: max-content;
      background-color: #fff;
      padding-top: 10vh;
      // z-index: 5;
      .menu-name {
        font-size: 40px;
        line-height: 40px;
        margin: 0 0 30px;
        &:hover {
          text-decoration: none;
        }
      }
    }
    @media (max-width: 767px) {
      width: 100vw;
      // max-width: 100vw;
      padding-right: 55px;
      height: 100vh;
      background-color: #fff;
      .row > * {
        // flex: none;
      }
      .menu-block {
        padding-left: 25px;
        padding-right: 25px;
        margin-left: 0;
        padding-top: 120px;
        width: auto;
        flex-direction: column;
        .menu-name {
          font-size: 36px;
          line-height: 42px;
        }
      }
    }
  }


  .main {
    .page-name h1 {
      font-size: 40px;
      line-height: 40px;
      flex-basis: 50%;
    }
    // .menu-block{
    //   padding:0;
    //   a {
    //     margin-top:10px;
    //   }
    // }
    .menu-block {
      h1 {
        font-size: 40px;
        line-height: 40px;
        margin: 0 0 30px;
      }

      @media (max-width: 767px) {
        // margin-top: 30px;
        padding-right: 55px;
      }
    }
    padding-bottom: 80px;
    .translate-block {
      a,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: Nimbus, sans-serif;
      }
      * {
        font-family: Crimson-text, serif;
      }
    }
    // min-height: calc(100vh - 270px);
    .translate-container {
      a,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: Nimbus, sans-serif;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 12px 0 60px;
      }
      .info-content {
        flex-basis: 50%;
      }
      * {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.46px;
        font-family: Crimson-text, serif;
      }
      a {
        display: block;
      }
    }
    min-height: calc(100vh);
    @include breakpoint(md) {
      padding-left: 200px;
      padding-top: 10vh;
    }
    background-color: $white;
  }
}

[class*="i18n"] {
  @include breakpoint(md) {
    padding: 0 30px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}

.nav {
  font-size: 22px;
  line-height: 29px;
}
.nav-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.nav-item {
  margin-bottom: 60px;
  flex-basis: 100%;
  div {
    justify-content: center;
  }
}
.menu-links {
  flex-basis: 50%;
  margin: 0 0 56px;
  h2 {
    margin: 0 0 12px !important;
    line-height: 40px !important;
    a {
      font-size: 30px !important;
      letter-spacing: 0.58px !important;
      line-height: 40px !important;
    }
  }
}
.row {
  h2 {
    a {
      font-size: 30px;
      letter-spacing: 0.58px;
      line-height: 32px;
    }
  }
}
.search-input-div {
  margin-top: 60px;
}

.modal-name {
  color: #fff;
  height: 60px;
  align-items: center;
  margin-bottom: 60px;
}
// .nav-link{
//   @include outline(0);
//   display: inline-block;
//   &.active {
//     text-decoration: underline;
//   }
// }

.not-display-on-mobile {
  display: none;
  @include breakpoint(md) {
    display: block;
  }
}
.underline {
  text-decoration: underline;
}

.link-with-icon {
  margin-top: auto;
  margin-bottom: auto;
}

.hide-on-mobile {
  @media (max-width: 575px) {
    // mobile
    display: none !important;
  }
}
.hide-on-767 {
  @media (max-width: 767px) {
    // mobile
    display: none !important;
  }
}
@media (max-width: 575px) {
  .main {
    padding: 120px 25px;
    .Catalog {
      .Item {
        .item-artists,
        .item-title {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
    .page-name {
      // margin-bottom: 0px;
      font-size: 36px;
      letter-spacing: 0;
      line-height: 42px;
    }
    .translate-container {
      > a,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      h1 a,
      h2 a,
      h3 a,
      h4 a,
      h5 a,
      h6 a {
        line-height: 34px !important;
        font-size: 26px !important;
        letter-spacing: 0.5px !important;
      }
      * {
        font-size: 20px !important;
        line-height: 26px !important;
        letter-spacing: 0.38px !important;
      }
    }
    .row {
      display: block;
    }
    .menu-block {
      flex-wrap: wrap;
      padding-bottom: 28px;
      h1 {
        font-size: 36px !important;
        line-height: 42px !important;
        margin: 0 0 20px !important;
      }
      .menu-links {
        margin: 0;
      }
    }
    .row .menu-links {
      flex-basis: 100%;
    }
  }
  .sticky-header {
    width: 100%;
    header {
      width: 100%;
      height: 100px;
      .header-container {
        display: flex;
        height: 100px;
        justify-content: space-between;
        padding: 20px 25px;
        .logo-container {
          padding-top: 0;
          width: fit-content;
          svg {
            width: 45px;
            height: 60px;
          }
        }
        .search-icon-div {
          position: relative;
          bottom: 0;
          z-index: 5;
          width: 50%;
          width: fit-content;
        }
      }
    }
  }
  footer {
    padding: 15px 25px !important;
    table tr {
      flex-wrap: wrap;
      td {
        flex-basis: 100% !important;
        margin-top: 40px;
      }
    }
  }
  .footer-menu {
    display: none !important;
  }
  .modal {
    padding: 20px 25px !important;
    .modal-container {
      display: block !important;
      .buttons {
        width: 100% !important;
        .nav-list {
          justify-content: space-between;
          .nav-item {
            flex-basis: auto;
          }
        }
      }
      .content {
        .page-name {
          font-size: 36px;
          line-height: 42px;
          letter-spacing: normal;
          div {
            width: 100%;
          }
        }
      }
    }
    .search-container {
      input {
        padding: 0 !important;
        font-size: 35px;
        line-height: 42px;
        letter-spacing: normal;
      }
    }
  }
  .locale-selector {
    display: flex !important;
  }
}
.locale-selector {
  display: none;
}

.menu-block {
  text-align: left;
  padding-bottom: 24px;
}

.menu-name {
  margin-bottom: 47px;
}

.row {
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  & > * {
    flex: 1 0;
  }
}
