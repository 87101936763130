.select-container {
  height: 32px !important;
  div[class$="-control"] {
    height: 32px !important;
    min-height: 32px !important;
    box-shadow: none !important;
    border: solid 1px #cecece !important;
    border-radius: 0;
    span[class$="-indicatorSeparator"] {
      display: none;
    }
    div[class$="-singleValue"] {
      padding-top: 4px;
      font-size: 12px;
    }
    div[class$="-indicatorContainer"] {
      padding: 5px;
    }
    div[class$="-indicatorContainer"] svg {
      transform: scale(0.6);
      fill: black;
    }
  }
  div[class$="-menu"] {
    border-radius: 10px !important;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0px 2px 6px 4px hsla(0, 0%, 0%, 0.1) !important;
  }
  div[class$="-MenuList"] {
    padding-bottom: 8px;
    padding-top: 8px;
  }
  div[class$="-option"] {
    padding: 10px 12px;
  }
}

.page-name{
  font-size: 40px;
  line-height: 40px;
  display: flex;
  margin-bottom:30px;
  div{
    width:50%;
  }
}

.Home {
  .Catalog {
    margin-top: 60px;
    @media (max-width: 575px) {
      margin-top: 40px;
    }
  }
  .loading-text,
  .no-results {
    font-size: 26px;
    margin-top: -20px;
    &:nth-child(3) {
      //if any items exist on page
      height: 68px; //button height
      margin-top: 30px; //20 margin-top of button + center of 48px button
    }
    span {
      font-family: sans-serif;
    }
  }
  @media (max-width: 575px) {
    .page-name {
      margin-bottom: 0;
    }
    //mobile
    // margin-top: 16px;
  }
  // @media (min-width: 576px) {
  //   .no-results {
  //   }
  // }
  .row {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @media (min-width: 768px) {
      flex-direction: row;
    }
    .selected-filter {
      justify-content: space-between;
      margin-bottom: 15px;
    }
    // &:first-of-type {
    //   flex-direction: column-reverse;
    //   margin-bottom: 20px;
    //   .select-container {
    //     margin-left: auto;
    //     margin-bottom: 15px;
    //     min-width: 195px;
    //     width: 195px;
    //   }
    //   @media (min-width: 768px) {
    //     // margin-bottom: 50px;
    //     flex-direction: row;
    //     .select-container {
    //       margin-bottom: 0;
    //       max-width: 210px;
    //       width: 210px;
    //     }
    //   }
    // }
    
    .select-container {
      display: flex;
      // margin: 15px 15px 0 0;
      align-items: center;
      justify-content: flex-start;
      @media (min-width: 768px) {
        justify-content: flex-end;
      }
      
      > span {
        margin-top: 3px;
        white-space: nowrap;
        // margin-top: 15px;
        font-size: 12px;
        margin-right: 8px;
        // display: inline;
      }
      > div {
        min-width: 135px;
        width: 135px;
        // display: inline-block;
      }
    }
  }
  .item-filter-container {
    width:100%;
    &.filter-children-opened {
      margin-bottom: 40px;
    }
    .filter-description {
      margin-top: 16px;
      font-size: 14.5px;
      margin-bottom: 16px;
    }
    .item-filter-wrapper {
      display: flex;
      flex-basis: 100%;
      @media (min-width: 576px) {
        // desktop
        // margin-right: 16px;
      }
      display: flex;
      @media (max-width: 575px) {
        //mobile
        margin-top: 16px;
        flex-direction: column;
      }
      .filter-option-container {
        &:not(:last-child) {
          margin-right: 16px;
        }
      }
      .items-count {
        font-size: 30px;
        line-height: 32px;
        letter-spacing: 0.58px;
        margin-bottom: 16px;
        flex-basis: 50%;
      }
      > *:not(:last-child) {
        &.item-filter {
          margin-bottom: 8px;
        }
      }
    }
    .item-filter button {
      font-size: 12px;
      padding: 9px 16px;
      width: auto !important;
    }
  }
  .load-more-button {
    border-radius: 18px;
    height: 48px;
    font-size: 14px;
    letter-spacing: 1.75px;
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.text {
  h1 {
    font-family: $global-font-family;
    font-size: 32px;
    @include breakpoint(sm-down) {
      font-size: 22px;
    }
    font-weight: lighter;
    line-height: 32px;
    letter-spacing: 0.3px;
    color: #131413;
    margin-bottom: 20px;
  }
  p {
    font-family: $global-font-family;
    font-size: 32px;
    line-height: 42px;
    @include breakpoint(sm-down) {
      font-size: 22px;
      line-height: 32px;
    }

    letter-spacing: 0.3px;
    color: #131413;
    max-width: 915px;
    a {
      text-decoration: none;
      border-bottom: 2px solid #000000;
      color: #000000;
      &:hover {
        color: $link-hover-color;
        border-bottom: 2px solid $link-hover-color;
      }
    }
  }
  strong,
  b {
    font-weight: 500;
  }
}

.item-filter div.selected-filter .toggle-button {
  button {
    border: 1px solid black;
    color: black;
    &:focus,
    &:hover,
    &:active {
      color: white;
    }
  }
  &.filter-option-container-selected button {
    border: 1px solid black;
    color: white;
    background: #6F2EB9;
    &:focus,
    &:hover,
    &:active {
      color: white;
      background: #6F2EB9;
      svg {
        fill: white !important;
      }
    }
    svg {
      fill: white !important;
    }
  }
}
.home-content {
  h2 {
    margin: 12px 0;
  }
  flex-basis: 50%;
}
.home-title{
  font-size:20px;
  padding: 60px 0px 20px 0;
}

.item-img-container img{
  width:100%
}

.infoLink {
  margin-top:40px;
}