.ItemPreview {
  $title-height: 50px;
  $line-height: 32px;
  //flex sum≈1080 (as width of design)
  // margin-top: 40px;
  @media (max-width: 575px) {
    //mobile
    margin-top: 0px;
  }
  .item {
    > div {
      flex-basis: 100%;
    }
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 575px) {
      //mobile
      flex-direction: column;
    }
    // > *:not(:last-child) {
    //   margin-right: 40px;
    //   @media (max-width: 575px) {
    //     //mobile
    //     margin-right: 0px;
    //     margin-bottom: 37px;
    //   }
    // }
    .title {
      display: flex;
      font-size: 40px;
      @media (max-width: 575px) {
        //mobile
        font-size: 36px;
        line-height: 42px;
        letter-spacing: normal;
      }
    }
    > .title {
      @media (min-width: 576px) {
        display: none;
      }
    }
    .item-image {
      width: 100%;
      text-align: center;
      img {
        margin: 10vh 0;
      }
    }
    .item-images {
      width: 100%;
      .slider-container {
        @media (max-width: 575px) {
          // width: auto;
          margin-left: -25px;
          margin-right: -25px;
        }
        border: none !important;
        margin: 0;
        .image-subtitle {
          padding-top: 10px;
          @media (max-width: 575px) {
            line-height: 18px;
            font-size: 12px;
            padding-left: 25px;
          }
        }
        .slick-slider {
          background-color: #fff;
          max-width: 1200px;
          height: 100%;
          width: 100%;
          margin-top: 40px;
          .slick-prev {
            z-index: 1;
            left: 40px;
            width: 35px;
            height: 35px;
          }
          .slick-next {
            z-index: 1;
            right: 40px;
            width: 35px;
            height: 35px;
          }
          .slick-list {
            .slick-track {
              align-items: center;
              display: flex;
              > div > div > div {
                display: block !important;
              }
              img {
                margin: 0 auto !important;
                max-height: 65vh;
                @media (max-width: 575px) {
                  height: 100%;
                  max-width: 100vw;
                  max-height: 260px;
                  width: auto;
                  height: auto;
                  // max-width: 50%;
                }
              }
            }
          }
        }
      }
    }
    .desc {
      > *:not(:last-child) {
        margin-bottom: 25px;
      }
      // flex: 710;
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      > .title {
        @media (max-width: 575px) {
          //mobile
          display: none;
        }
      }
      .not-title {
        .block-description {
          margin-top: 60px;
          flex-basis: 100%;
          display: flex;
          justify-content: space-between;
          .translate-block {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;
          }
        }
        > *:not(:last-child) {
          // margin-right: 40px;
          @media (max-width: 575px) {
            //mobile
            margin-right: 0px;
            margin-bottom: 10px;
            margin-top: 20px;
          }
        }
        @media (max-width: 575px) {
          //mobile
          flex-direction: column;
        }
        display: flex;
        font-size: 12px;
        line-height: 18px;
        flex-wrap: wrap;

        .description-text {
          white-space: pre-wrap;
          font-size: 24px;
          line-height: 32px;
          letter-spacing: 0.46px;
          flex: 1 0 50%;
          padding: 0 30px;
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
          @media (max-width: 575px) {
            padding: 0;
            flex-basis: 100%;
            font-size: 20px;
            line-height: 26px;
            margin-bottom: 20px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .description-text-container {
          border: 1px solid #000;
          padding: 20px;
          margin-top: 60px;
          flex-basis: 100%;
          flex: 410;
          display: flex;
          justify-content: space-between;
          * {
            letter-spacing: 0.2px !important;
          }
          @media (max-width: 575px) {
            flex-wrap: wrap;
            margin-top: 20px;
          }
          > *:not(:last-child) {
            // margin-bottom: 22px;
            @media (max-width: 575px) {
              //mobile
              margin-bottom: 24px;
            }
          }
          .small-description {
            flex-basis: 20%;
            @media (max-width: 575px) {
              flex-basis: 100%;
            }
            &:last-of-type {
              @media (max-width: 575px) {
                //mobile
                margin-bottom: 24px;
              }
            }
          }
          > .item-filters {
            @media (max-width: 575px) {
              // display: none;
              flex-basis: 100%;
            }
            // margin-bottom: 22px;
            flex-basis: 20%;
            > .item-filter {
              button {
                width: auto;
              }
              // &:not(:last-child) {
              // margin-bottom: 8px;
              // }
            }
          }
          .link-with-icon {
            margin-top: 2px;
            margin-bottom: 2px;
            span {
              // font-anchor vertical compensation
              margin-top: 2px;
              margin-bottom: -2px;
            }
          }
        }
        .misc {
          flex: 300;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          > *:not(:last-child) {
            margin-bottom: 22px;
            @media (max-width: 575px) {
              //mobile
              margin-bottom: 37px;
            }
            &.item-filter {
              margin-bottom: 8px;
            }
          }
          .item-filters {
            @media (max-width: 575px) {
              //mobile
              display: none;
            }
          }
          .links > a {
            &:not(:last-child) {
              margin-bottom: 12px !important;
              display: block;
            }
          }
        }
      }
    }
  }
  .related-books-container {
    margin-top: 50px;
    .related-books-titles {
      display: flex;
      .related-books-title {
        flex-basis: 50%;
        margin: 0px 0px 30px 0px;
        font-size: 25px;
        @media (max-width: 575px) {
          //mobile
          font-size: 26px;
          flex-basis: 100%;
          line-height: 34px;
        }
      }
    }

    .all-related-books-btn {
      width: 100%;
      height: 50px;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 1.75px;
      text-transform: uppercase;
      margin-top: 20px;
    }
  }
}
